<template>
  <div class="row justify-center q-pa-lg">
    <div class="col">
      <div class="column q-gutter-y-lg">
        <div class="flex justify-center">
          <router-link to="/">
            <q-img
              src="~/assets/Logo.svg"
              width="200px"
            />
          </router-link>
        </div>
        <ErrorBoundary
          :is-error="isError"
          :component="() => import('pages/LinkNotAvailablePage')"
        >
          <div class="row justify-center">
            <div class="col-4">
              <FormRender
                v-if="form"
                :external-form="form"
                :form-guid="guid"
                :current-step="currentStep"
                preview
                @apply-step="currentStep = $event"
              />
            </div>
          </div>
        </ErrorBoundary>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorBoundary from 'common/components/ErrorBoundary';
import { transformObjectToFormState } from 'common/helpers/formStore';
import handleError from 'common/helpers/handleError';
import FormRender from 'common/render/FormRender';

export default {
  name: 'FormPreviewLayout',
  components: {
    ErrorBoundary,
    FormRender,
  },
  props: {
    guid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: undefined,
      isError: false,
      currentStep: 'step1',
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      try {
        this.$q.loading.show();
        this.isError = false;
        const { data } = await this.$axios.get(`forms/${this.guid}`);
        this.form = await transformObjectToFormState(data.data);
      } catch (error) {
        this.isError = true;
        handleError(error);
      } finally {
        this.$q.loading.hide();
      }
    },
  },
};
</script>

<style scoped>

</style>
